import { graphql } from 'gatsby';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { addAuthentication } from '../../../components/addAuthentication';
import './scss/ChangePlanPage.scss';
import {
  DashboardPageHeading,
  IsolatedPaymentMethodForm,
  PlanChange,
} from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';
import { ConfirmBox, SplashScreen } from '../../../components/misc';
import { DispatchContext, StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import {
  APP_SET_USER_DATA,
  APP_SET_USER_SUBSCRIPTION_DATA,
} from '../../../lib/events/app/types';
import { useScript } from '../../../util/useScript';
import { isAnnualPlan, isFreePlan, isPremiumPlan } from '../../../lib/utils';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => {
  const [recurlyReady, setRecurlyReady] = useState(false);
  const recurlyStatus = useScript('https://js.recurly.com/v4/recurly.js');
  const [needsConfirmation, setNeedsConfirmation] = useState(null);
  const [loading, setLoading] = useState(true);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { user, variables } = state || {};
  const { subscription } = user || {};
  const [currentPlan, setCurrentPlan] = useState(
    subscription ? subscription.plan.code : 'starter'
  );
  const currentUserAmountState = useState(
    subscription ? subscription.addOns[0].quantity : 1
  );
  const [currentUserAmount, setCurrentUserAmount] = currentUserAmountState;
  const [needBillingInformation, setNeedBillingInformation] = useState(false);
  const editingPlanState = useState(false);
  const changedPlanState = useState(currentPlan);
  const changedUserAmountState = useState(currentUserAmount);
  const annualCommitmentState = useState(false);
  const [changedPlan, setChangedPlan] = changedPlanState;
  const [changedUserAmount, setChangedUserAmount] = changedUserAmountState;
  const [, setAnnualCommitment] = annualCommitmentState;
  const localStorageAvailable = typeof localStorage !== 'undefined';

  // Local storage is used to prevent a delay between renders
  const [providerCount, setProviderCount] = useState(
    parseInt(
      localStorageAvailable && localStorage.getItem('amwellProviderCount'),
      10
    )
  );
  const [providerAccounts, setProviderAccounts] = useState(
    localStorageAvailable && localStorage.getItem('amwellProviderAccounts')
  );

  let count = 0;

  Amwell.fetchProviderAccounts()
    .then((providerAccounts) => {
      if (providerAccounts) {
        // eslint-disable-next-line no-param-reassign
        providerAccounts = providerAccounts
          .filter(
            // eslint-disable-next-line camelcase
            ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Provider'
          )
          .sort((a, b) => {
            const A = a.Name ? a.Name.toLowerCase() : '';
            const B = b.Name ? b.Name.toLowerCase() : '';

            if (A > B) {
              return 1;
            }
            if (A < B) {
              return -1;
            }
            return 0;
          });
        count = providerAccounts.length;
      }

      if (localStorageAvailable) {
        if (
          localStorage.getItem('amwellProviderAccounts') == null ||
          JSON.stringify(providerAccounts) !==
            localStorage.getItem('amwellProviderAccounts')
        ) {
          localStorage.setItem(
            'amwellProviderAccounts',
            JSON.stringify(providerAccounts)
          );
          setProviderAccounts(providerAccounts);
        }
      }

      if (providerCount !== count) {
        if (localStorageAvailable) {
          localStorage.setItem('amwellProviderCount', count);
        }
        setProviderCount(count);
      }
    })
    .catch((err) => {
      console.error(err.toString());
    });

  // Make plan code
  function getPlanCode() {
    const codePrefix = currentPlan.split('-')[0];
    if (isFreePlan(changedPlan)) {
      return `${codePrefix}-starter`;
    }
    return 'awn-pp';
  }

  // Make addon
  function getAddOns() {
    const addOn = {
      code: 'awnpp-provider',
      quantity: changedUserAmount,
    };
    return [addOn];
  }

  useEffect(() => {
    setRecurlyReady(recurlyStatus === 'ready');
  }, [recurlyStatus, recurlyReady]);

  /**
   * (Free Plan -> Premium Plan) Handle plan upgrade
   */
  const handleFreePlanUpgrade = ({ plan, userAmount, annualCommitment }) => {
    console.log('Handle Free to Premium Plan Upgrade');
    setNeedBillingInformation(true);
  };

  /**
   * (Free Plan -> Premium Plan) called after the billing information is submitted
   */
  const handleBillingInfoSubmitted = async ({ token }) => {
    const billingInfoPayload = { token_id: token.id };

    // PUT /subscriptions

    const subscriptionUpdatePayload = {
      plan_code: getPlanCode(),
      addOns: getAddOns(),
    };
    console.log('handleBillingInfoSubmitted', {
      subscriptionUpdatePayload,
      billingInfoPayload,
      changedPlan,
    });

    setNeedsConfirmation({
      onConfirm: confirmUpgrade,
      onCancel: resetChanges,
      heading: 'Confirm Plan Upgrade',
      message: `Are you sure you want to upgrade your plan? Changes will take effect immediately.`,
      confirmLabel: 'Yes, Upgrade My Plan',
      cancelLabel: 'No, Keep My Plan',
      successHeading: 'Your plan has been updated',
      successMessage: 'Changes should take effect immediately',
    });

    async function confirmUpgrade() {
      try {
        const billingInfoRes = await Amwell.updateBillingInformation(
          billingInfoPayload
        );
        console.log('billingInfoRes', billingInfoRes);

        await commitPlanChanges(subscriptionUpdatePayload);

        setNeedsConfirmation((val) => ({
          ...val,
          processing: false,
          success: true,
        }));
      } catch (error) {
        setNeedsConfirmation((val) => ({
          ...val,
          processing: false,
          success: false,
          failed: true,
        }));
      }
    }
  };

  /**
   * (Premium Plan) Check if user amount changed
   */
  const handlePremiumPlanUserChange = async (providerIdsToDelete) => {
    const subscriptionUpdatePayload = {
      plan_code: getPlanCode(),
      addOns: getAddOns(),
    };
    setNeedsConfirmation((val) => ({ ...val, processing: true }));
    setNeedBillingInformation(false);
    console.log('Handle Premium plan user amount change', {
      changedUserAmount,
      subscriptionUpdatePayload,
    });
    try {
      await commitPlanChanges(subscriptionUpdatePayload, providerIdsToDelete);
      setNeedsConfirmation((val) => ({
        ...val,
        processing: false,
        success: true,
      }));
    } catch (error) {
      setNeedsConfirmation((val) => ({
        ...val,
        processing: false,
        success: false,
        failed: true,
      }));
    }
  };

  /**
   * (Premium Plan) Handle plan downgrade
   */
  const handlePremiumPlanDowngrade = async (providerIdsToDelete) => {
    const subscriptionUpdatePayload = {
      plan_code: getPlanCode(),
      addOns: getAddOns(),
    };
    setNeedsConfirmation((val) => ({ ...val, processing: true }));
    setNeedBillingInformation(false);
    console.log('Handle Premium to Free Plan downgrade', {
      changedPlan,
      subscriptionUpdatePayload,
    });

    try {
      await commitPlanChanges(subscriptionUpdatePayload, providerIdsToDelete);
      setNeedsConfirmation((val) => ({
        ...val,
        processing: false,
        success: true,
      }));
    } catch (error) {
      setNeedsConfirmation((val) => ({
        ...val,
        processing: false,
        success: false,
        failed: true,
      }));
    }
  };

  async function commitPlanChanges(
    subscriptionUpdatePayload,
    providerIdsToDelete
  ) {
    setNeedsConfirmation((val) => ({ ...val, processing: true }));
    const planUpdateRes = await Amwell.updateSubscription(
      subscriptionUpdatePayload
    );

    if (providerIdsToDelete) {
      const deleteProviderPromises = [];
      for (const providerId of providerIdsToDelete) {
        deleteProviderPromises.push(
          Amwell.deleteProvider(null, {
            provider: providerAccounts.find(
              (account) => account.Id === providerId
            ),
          })
        );
      }
      await Promise.all(deleteProviderPromises);
    }

    console.log('planUpdateRes', planUpdateRes);
    const { user, subscriptionData } = await Amwell.fetchCurrentUserInfo();
    dispatch({ type: APP_SET_USER_DATA, payload: { user } });
    dispatch({
      type: APP_SET_USER_SUBSCRIPTION_DATA,
      payload: { subscription: subscriptionData[0] },
    });
  }

  /**
   * Handle no change
   */
  const resetChanges = (success) => {
    if (success !== true) {
      console.log("No changes made... resetting")
      setChangedUserAmount(currentUserAmount)
    }
    setNeedBillingInformation(false);
    setNeedsConfirmation(null);
  };

  const handlePlanChange = ({ action, plan, userAmount, annualCommitment }) => {
    let message;
    switch (action) {
      case 'upgrade-to-premium':
        console.log('upgrade-to-premium');
        if (isPremiumPlan(plan)) {
          handleFreePlanUpgrade({ plan, userAmount, annualCommitment });
        } else if (isFreePlan(plan)) {
          console.log('No changes were made to the Free Plan');
          resetChanges();
        }
        break;
      case 'update-user-amount':
        console.log('update-user-amount');
        message =
          userAmount < providerCount
            ? `You currently have ${providerCount} providers. If you reduce your
          plan to ${userAmount} seats, select ${providerCount - userAmount}
          provider(s) to remove from your plan.
          This cannot be undone. Are you sure you wish to continue?`
            : `Are you sure you want to modify the amount of users under your plan
          to ${userAmount} users? Changes will take effect immediately.`;
        setNeedsConfirmation({
          onConfirm: handlePremiumPlanUserChange,
          onCancel: resetChanges,
          heading: 'Confirm Plan Update',
          message,
          confirmLabel: 'Yes, Update My Plan',
          cancelLabel: 'No, Never Mind',
          successHeading: 'Your plan has been updated',
          successMessage: 'Changes should take effect immediately',
          providerAccounts:
            userAmount < providerCount ? providerAccounts : null,
          providerDeleteCount:
            userAmount < providerCount ? providerCount - userAmount : null,
        });
        break;
      case 'downgrade-to-starter':
        console.log('downgrade-to-starter');
        message =
          userAmount < providerCount
            ? `You currently have ${providerCount} providers. If you reduce your
          plan to ${userAmount} seats, select ${providerCount - userAmount}
          provider(s) to remove from your plan.
          This cannot be undone. Are you sure you wish to continue?`
            : `Are you sure you want to modify the amount of users under your plan
          to ${userAmount} users? Changes will take effect immediately.`;
        setNeedsConfirmation({
          onConfirm: handlePremiumPlanDowngrade,
          onCancel: resetChanges,
          heading: 'Confirm Plan Downgrade',
          message,
          confirmLabel: 'Yes, Downgrade My Plan',
          cancelLabel: 'No, Never Mind',
          successHeading: 'Your plan has been updated',
          successMessage: 'Changes should take effect immediately',
          providerAccounts:
            userAmount < providerCount ? providerAccounts : null,
          providerDeleteCount:
            userAmount < providerCount ? providerCount - userAmount : null,
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (subscription) {
      setCurrentPlan(subscription.plan.code);
      setChangedPlan(subscription.plan.code);
      setCurrentUserAmount(subscription.addOns[0].quantity);
      setChangedUserAmount(subscription.addOns[0].quantity);
      setAnnualCommitment(isAnnualPlan(subscription.plan.code));
    }
  }, [
    subscription,
    setChangedUserAmount,
    setChangedPlan,
    setAnnualCommitment,
    setCurrentUserAmount,
  ]);

  useEffect(() => {
    if (!subscription) {
      (async () => {
        const subscriptionData = await Amwell.fetchSubscriptionInformation();
        dispatch({
          type: APP_SET_USER_SUBSCRIPTION_DATA,
          payload: { subscription: subscriptionData[0] },
        });
      })();
    }

    setLoading(!subscription);
  }, [subscription, dispatch]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Dashboard - Change Plan</title>
      </Helmet>
      <DashboardLayout>
        <div className="ChangePlanPage">
          <DashboardPageHeading
            heading={'Plan & Payment'}
            links={[{ label: 'My Plan & Payment', to: '/dashboard/plan' }]}
            partiallyActive={true}
          />
          <div className="ChangePlanPage-container">
            {recurlyReady && !loading ? (
              <>
                <RecurlyProvider
                  publicKey={process.env.GATSBY_RECURLY_PUBLIC_KEY}
                >
                  <Elements>
                    <PlanChange
                      currentPlan={currentPlan}
                      subscription={subscription}
                      providerCount={providerCount}
                      onContinue={handlePlanChange}
                      editingPlanState={editingPlanState}
                      changedPlanState={changedPlanState}
                      changedUserAmountState={changedUserAmountState}
                      currentUserAmountState={currentUserAmountState}
                      annualCommitmentState={annualCommitmentState}
                      allowPlanSwitching={variables.allowPlanSwitching}
                    />
                    {needBillingInformation ? (
                      <IsolatedPaymentMethodForm
                        onFormSubmit={handleBillingInfoSubmitted}
                      />
                    ) : null}

                    {needsConfirmation ? (
                      <ConfirmBox
                        onConfirm={needsConfirmation.onConfirm}
                        onCancel={needsConfirmation.onCancel}
                        confirmLabel={needsConfirmation.confirmLabel}
                        cancelLabel={needsConfirmation.cancelLabel}
                        heading={needsConfirmation.heading}
                        message={needsConfirmation.message}
                        processing={needsConfirmation.processing}
                        success={needsConfirmation.success}
                        failed={needsConfirmation.failed}
                        successHeading={needsConfirmation.successHeading}
                        successMessage={needsConfirmation.successMessage}
                        failedHeading={needsConfirmation.failedHeading}
                        failedMessage={needsConfirmation.failedMessage}
                        providerAccounts={needsConfirmation.providerAccounts}
                        providerDeleteCount={
                          needsConfirmation.providerDeleteCount
                        }
                      />
                    ) : null}
                  </Elements>
                </RecurlyProvider>
              </>
            ) : (
              <SplashScreen />
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
});
